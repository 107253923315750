import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PrimaryButton from 'src/components/PrimaryButton';
import Modal from 'react-bootstrap/Modal';
import SingleAccordion from 'src/components/SingleAccordion';
import PermissionFilter from '../auth/PermissionsFilter';
import { CognitoGroups } from 'src/constants/cognitoGroups';
import { formatReportDateTime } from 'src/lib/dateUtils';
import ReportButton from './ReportButton';
import BriefSummaryButton from './BriefSummaryButton';


const Reports = ({ className }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <PrimaryButton className={className} onClick={() => setShowModal(true)}>
        Get Reports
      </PrimaryButton>
      <Modal
        animation={true}
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop="static"
        size="md"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Reports</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PermissionFilter any={[CognitoGroups.ADMIN, CognitoGroups.ADVANCED]}>
            <SingleAccordion
              header="Flood Gauge Summary & Impact Report"
              eventKey="0"
              defaultEventKey="0"
            >
              <div className="flex-lg-fill d-flex align-items-center justify-content-center flex-lg-shrink-1 gap-4">
                <ReportButton
                  type="excel"
                  text="Excel Report"
                  query="name=flood-gauge-impact-summary&format=excel"
                />
                <ReportButton
                  type="pdf"
                  text="PDF Report"
                  query="name=flood-gauge-impact-summary&format=pdf"
                />
              </div>
            </SingleAccordion>
          </PermissionFilter>
          <PermissionFilter any={[CognitoGroups.ADMIN, CognitoGroups.ADVANCED]}>
            <SingleAccordion
              header="All Gauges Summary"
              eventKey="1"
              defaultEventKey="1"
            >
              <div className="flex-lg-fill d-flex align-items-center justify-content-center flex-lg-shrink-1 gap-4">
                <ReportButton
                  type="excel"
                  text="Excel Report"
                  query="name=all-gauge-summary&format=excel"
                  dynamicQueryParams={() => {
                    return {
                      dt: formatReportDateTime(new Date())
                    };
                  }}
                />
                <ReportButton
                  type="pdf"
                  text="PDF Report"
                  query="name=all-gauge-summary&format=pdf"
                  dynamicQueryParams={() => {
                    return {
                      dt: formatReportDateTime(new Date())
                    };
                  }}
                />
              </div>
            </SingleAccordion>
          </PermissionFilter>
          <PermissionFilter any={[CognitoGroups.ADMIN]}>
            <SingleAccordion
              header="Flood Brief Report"
              eventKey="2"
              defaultEventKey="2"
            >
              <div className="flex-lg-fill d-flex align-items-center justify-content-center flex-lg-shrink-1 gap-4">
                <BriefSummaryButton />
              </div>
            </SingleAccordion>
          </PermissionFilter>
          <PermissionFilter any={[CognitoGroups.ADMIN, CognitoGroups.ADVANCED]}>
            <SingleAccordion
              header="Roads Summary"
              eventKey="3"
              defaultEventKey="3"
            >
              <div className="flex-lg-fill d-flex align-items-center justify-content-center flex-lg-shrink-1 gap-4">
                <ReportButton
                  type="excel"
                  text="Excel Report"
                  query="name=road-summary&format=excel"
                  dynamicQueryParams={() => {
                    return {
                      dt: formatReportDateTime(new Date())
                    };
                  }}
                />
                <ReportButton
                  type="pdf"
                  text="PDF Report"
                  query="name=road-summary&format=pdf"
                  dynamicQueryParams={() => {
                    return {
                      dt: formatReportDateTime(new Date())
                    };
                  }}
                />
              </div>
            </SingleAccordion>
          </PermissionFilter>
          <PermissionFilter any={[CognitoGroups.ADMIN, CognitoGroups.ADVANCED]}>
            <SingleAccordion
              header="Bridges Summary"
              eventKey="4"
              defaultEventKey="4"
            >
              <div className="flex-lg-fill d-flex align-items-center justify-content-center flex-lg-shrink-1 gap-4">
                <ReportButton
                  type="excel"
                  text="Excel Report"
                  query="name=bridge-summary&format=excel"
                  dynamicQueryParams={() => {
                    return {
                      dt: formatReportDateTime(new Date())
                    };
                  }}
                />
                <ReportButton
                  type="pdf"
                  text="PDF Report"
                  query="name=bridge-summary&format=pdf"
                  dynamicQueryParams={() => {
                    return {
                      dt: formatReportDateTime(new Date())
                    };
                  }}
                />
              </div>
            </SingleAccordion>
          </PermissionFilter>
        </Modal.Body>
      </Modal>
    </>
  );
}

Reports.propTypes = {
  className: PropTypes.string
}

export default Reports;