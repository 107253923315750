import React from 'react';
import Help from 'src/features/help/Help';
import HelpSm from 'src/features/help/HelpSm';
import { Card } from 'react-bootstrap';
import emailAlert from 'src/assets/images/email_alert.png';
import textAlert from 'src/assets/images/text_alert.png';
import alertSettings from 'src/assets/images/alert_settings.png';
//import createAlerts from 'src/assets/images/create_alerts.mp4';
//import removeAlerts from 'src/assets/images/remove_alerts.mp4';
import useCustomMediaQuery from 'src/lib/hooks/useCustomMediaQuery';
import ReactPlayer from 'react-player';

// eslint-disable-next-line
const CardWrapper = ({ isDesktop, children }) => {
  return (
    <Card className={`dark ${isDesktop ? 'w-50' : ''}`}>
      <Card.Body>{children}</Card.Body>
    </Card>
  );
};

const HelpPage = () => {
  const { isDesktop } = useCustomMediaQuery();
  const helpItems = [
    {
      key: 'home',
      title: 'General Info',
      details: (
        <CardWrapper isDesktop={isDesktop}>
          <div>
            <p>
              Any FIMAN user can receive flooding alerts by signing in with an
              email address. After signing in, users can also provide a phone
              number, if they would like to receive text alerts.
            </p>
            <p>
              Users can sign in by clicking the button in the top-right and
              selecting their email provider. At the moment, users can choose
              from Gmail or Apple. State and local government employees can use
              their Microsoft email username and password if they have an NCID
              account.
            </p>
            <p>
              The FIMAN site will redirect users to the provider’s website to
              sign in with that account. FIMAN never receives email account
              passwords, just approval from the provider. NCEM has no way to
              access an email account or reset a password. If you are having
              trouble signing in with an account, please contact the provider.
            </p>
          </div>
        </CardWrapper>
      ),
    },
    {
      key: 'subscribe',
      title: 'Subscribe',
      details: (
        <CardWrapper isDesktop={isDesktop}>
          <div>
            <p>To start receiving alerts for a gauge, follow these steps:</p>
            <ol>
              <li>
                Locate a gauge using the map, or by typing in the search bar
                above the map.{' '}
              </li>
              <li>
                After clicking on the point, click the orange “Subscribe” button
                in the top-right of the drawer.
              </li>
              <li>
                The “Alert Settings” window will open, and you can select the
                conditions that will trigger an alert.
              </li>
              <li>
                When you have the alerts set up the way you want, just close the
                window and your settings will be saved.
              </li>
            </ol>
            <p>
              Users can view all of the gauges they are subscribed to by
              clicking the bell icon in the top-right corner. From there, you
              can go to a specific gauge, or unsubscribe from everything in one
              quick step.
            </p>
          </div>
          <br />
          <div style={{ width: '100%' }}>
            <h5>Subscribe To Alerts Video Example</h5>
            <ReactPlayer
              url={process.env.PUBLIC_URL + '/create_alerts.mp4'}
              playing={false}
              controls={true}
              loop={false}
              muted={false}
              playsinline={true}
            />
          </div>
        </CardWrapper>
      ),
    },
    {
      key: 'customAlerts',
      title: 'Customize Alerts',
      details: (
        <CardWrapper isDesktop={isDesktop}>
          <div>
            <p>
              For each flooding condition (Monitor, Minor, Moderate, Major), you
              can receive an alert when water levels rise above or fall below
              the water level of that condition. If forecast data is available
              for a gauge, you can also receive alerts when the gauge is
              forecasted to rise above or fall below a water level.
            </p>
            <p>
              You can also create a custom alert if you have another water level
              you’d like to monitor. In the “Alert Settings” window, click “New
              Alert”. Then, name the alert, and select the stage or elevation
              that will trigger an alert. You can then select when to alert you
              (water rises above or falls below), and close the window to save
              your changes.
            </p>
          </div>
          <br />
          <div>
            <h5>Customize Alerts Example</h5>
            <img
              src={alertSettings}
              alt="custom alerts"
              style={{ width: '100%' }}
            />
          </div>
        </CardWrapper>
      ),
    },
    {
      key: 'emailAlerts',
      title: 'Email Alerts',
      details: (
        <CardWrapper isDesktop={isDesktop}>
          <div>
            <p>
              FIMAN can send alerts to the email address you used to sign in. In
              the “My Profile” pop-up box, click the on/off switch to toggle
              email alerts. You’ll receive a pop-up message confirming your
              decision.{' '}
            </p>
            <p>
              Whenever one of your subscriptions is triggered, you’ll receive an
              email from FIMAN with details about the current flooding
              situation. From the email, you can visit FIMAN, email our help
              account, or unsubscribe from future FIMAN alerts.
            </p>
          </div>
          <br />
          <div>
            <h5>Sample Email Alert</h5>
            <img src={emailAlert} alt="email alert" style={{ width: '100%' }} />
          </div>
        </CardWrapper>
      ),
    },
    {
      key: 'textAlerts',
      title: 'Text Alerts',
      details: (
        <CardWrapper isDesktop={isDesktop}>
          <div>
            <p>
              After signing in, you can set up text alerts. Click on your name
              in the top-right corner, and select “My Profile”. A new pop-up box
              will open with a line that says “Phone Number”. Click the yellow
              “Edit” button to enter your number, then click “Save”.
            </p>
            <p>
              You will then need to opt in to begin receiving alerts. To confirm
              text alerts are going to the right person, FIMAN will send you a
              confirmation text. In the “My Profile” pop-up box, click “Phone
              Opt-in". After confirming the text, you should see a line reading
              “Text Alerts: Confirmed”. You can opt in and out again twice in a
              30-day period.
            </p>
          </div>
          <br />
          <div>
            <h5>Sample Text Alert</h5>
            <img src={textAlert} alt="text alert" style={{ width: '100%' }} />
          </div>
        </CardWrapper>
      ),
    },
    {
      key: 'unsub',
      title: 'Unsubscribe',
      details: (
        <CardWrapper isDesktop={isDesktop}>
          <div>
            <p>
              There are several different ways to stop receiving FIMAN alerts.
            </p>
            <ul>
              <li>
                Click the bell icon in the top-right corner to see all of your
                subscriptions, then click “Unsubscribe All” to unsubscribe from
                all of them at once.{' '}
              </li>
              <ul className="pl-1">
                <li>
                  You can also click “Unsubscribe” under an individual gauge to
                  only unsubscribe from alerts at that gauge.
                </li>
              </ul>

              <li>
                Click on a gauge, then click the “Unsubscribe” button in the
                bottom drawer to unsubscribe from all alerts at that gauge.
              </li>
              <li>
                Reply to a text alert with “STOP” to unsubscribe from text
                alerts only.
              </li>
              <li>
                Click the link that says “To opt out of all FIMAN Alert emails,
                please click here” at the bottom of an email alert to
                unsubscribe from email alerts only.
              </li>
            </ul>
          </div>
          <br />
          <div style={{ width: '100%' }}>
            <h5>Unsubscribe To Alerts Video Example</h5>
            <ReactPlayer
              url={process.env.PUBLIC_URL + '/remove_alerts.mp4'}
              playing={false}
              controls={true}
              loop={false}
              muted={false}
              playsinline={true}
            />
          </div>
        </CardWrapper>
      ),
    },
    {
      key: 'faq',
      title: 'FAQ',
      details: (
        <CardWrapper isDesktop={isDesktop}>
          <div>
            <p>
              <b>
                Q: Can I attach multiple email addresses or phone numbers to one
                FIMAN login?{' '}
              </b>
            </p>
            <p>
              <b>A:</b> No, the email address that you log in with is the email
              address that will receive FIMAN alerts. Likewise, you can only
              attach one phone number to that email address. To send alerts to
              other email addresses and phone numbers, you will need to sign in
              under a different email address.
            </p>

            <p className="mt-5">
              <b>Q: How are new gauges added to FIMAN?</b>
            </p>
            <p>
              <b>A:</b> FIMAN gauges belong to a wide variety of federal/state
              agencies, cities and towns, community groups, research
              institutions, and private companies. If your agency or
              municipality has a gauge that could be added to FIMAN, please
              contact us at fimanhelp@ncdps.gov for further discussion.
            </p>

            <p className="mt-5">
              <b>Q: How often are gauge readings updated in FIMAN?</b>
            </p>
            <p>
              <b>A:</b> Every fifteen minutes FIMAN is updated with the latest
              available gauge readings
            </p>

            <p className="mt-5">
              <b>
                Q: Why don’t all sites have inundation scenarios, forecasts, or
                rainfall measurements?
              </b>
            </p>
            <p>
              <b>A:</b> The current gauge reading is the fundamental piece of
              information we can provide about flooding in an area. Whenever we
              can, we provide any available additional data to support
              decision-making. Adding a rain gauge to a stream gauge or
              developing reliable flooding models requires substantial time and
              resources, but we are always working to add more data to existing
              gauges.
            </p>

            <p className="mt-5">
              <b>
                Q: The gauge I'd like to see is no longer available in FIMAN.
                Where did it go?
              </b>
            </p>
            <p>
              <b>A:</b> If you don’t see a gauge when you first visit the FIMAN
              map, it is most likely still in place, but temporarily out of
              service. Click the yellow Filters button in the top-right corner,
              between the Search bar and the bell icon. Then check the “Out of
              Service” box to see all gauges that are currently not operating,
              which will be blue-gray points with an “X” in the middle. Our
              staff work to maintain hundreds of gauges around the state and
              will have your nearby gauges back up as soon as possible.
            </p>
          </div>
        </CardWrapper>
      ),
    },
    {
      key: 'privacyPolicy',
      title: 'Privacy Policy',
      details: (
        <CardWrapper isDesktop={isDesktop}>
          <div>
            <p>
              NC.gov is striving to provide a secure, efficient and friendly
              digital government experience. This privacy statement addresses
              the collection, use, disclosure and security of information that
              may be obtained when you visit North Carolina government websites
              that have this privacy statement in the footer.
            </p>
            <h5>Information Collected and Stored Automatically</h5>
            <p>
              When you visit our website to browse, read pages, submit forms, or
              download information, we automatically collect and store the
              following information:
            </p>
            <ul>
              <li>
                The Internet domain and IP address from which you access our
                portal;
              </li>
              <li>The date and time you access our site;</li>
              <li>The pages you visit; </li>
              <li>
                If you linked to this website from another website, the address
                of that website.
              </li>
              <li>
                IP addresses, which are numbers automatically assigned to a
                computer when using the Internet;
              </li>
              <li>
                The type of browser and operating system used to access the
                site;
              </li>
              <li>
                The search terms used to get to the website, in addition to
                search terms used in the site’s search engine;
              </li>
              <li>The dates and times users access the site;</li>
              <li>The type of device used to access the site;</li>
              <li>How often users visit the site;</li>
              <li>The pages users visit within the site; and</li>
              <li>The links made to other websites through this site.</li>
            </ul>
            <p>
              This information is used to help make the site more useful to
              visitors, to learn about the number of visitors to the site and
              the types of technology visitors use.
            </p>
            <p>
              The state uses Google Analytics measurement software to collect
              the information listed above. The data is automatically sent to
              Google’s system and the system immediately aggregates the data.
              Neither the state of North Carolina nor Google ever have access to
              the specifics of your particular site visits. The staff can only
              see the aggregate data from all users for a particular time
              period.
            </p>
            <p>
              Accounts on this site can be anonymized or deleted at the users
              request. Contact the administrator{' '}
              <a href="mailto:fimanhelp@ncdps.gov">fimanhelp@ncdps.gov</a>, for
              details. Please note, account deletion will lead to the deletion
              of all personal data and it cannot be recovered.
            </p>
            <h5>Unsubscribe</h5>
            <p>
              <p>
                There are several different ways to stop receiving FIMAN alerts.
              </p>
              <ul>
                <li>
                  Click the bell icon in the top-right corner to see all of your
                  subscriptions, then click “Unsubscribe All” to unsubscribe
                  from all of them at once.{' '}
                </li>
                <ul className="pl-1">
                  <li>
                    You can also click “Unsubscribe” under an individual gauge
                    to only unsubscribe from alerts at that gauge.
                  </li>
                </ul>

                <li>
                  Click on a gauge, then click the “Unsubscribe” button in the
                  bottom drawer to unsubscribe from all alerts at that gauge.
                </li>
                <li>
                  Reply to a text alert with “STOP” to unsubscribe from text
                  alerts only.
                </li>
                <li>
                  Click the link that says “To opt out of all FIMAN Alert
                  emails, please click here” at the bottom of an email alert to
                  unsubscribe from email alerts only.
                </li>
              </ul>
            </p>
            <h5>Website Security</h5>
            <p>
              For website security purposes and to ensure that this service
              remains available to all users, this computer system employs
              software programs to monitor network traffic to identify
              unauthorized attempts to upload or change information, or
              otherwise cause damage. Use of this site constitutes consent to
              such monitoring. Unauthorized attempts to upload information
              and/or change information on this website are strictly prohibited
              and subject to legal action to include the Computer Fraud and
              Abuse Act of 1986.
            </p>
            <h5>Public Disclosure</h5>
            <p>
              Government records in North Carolina generally are public in
              nature. The public policy of the State of North Carolina as
              established by the General Assembly is to "provide that, as a
              general rule, the public would have liberal access to public
              records."
            </p>
            <p>
              The North Carolina Public Records Law states that public records
              "shall mean all documents papers, letters, maps, books,
              photographs, films, sound recordings, magnetic or other tapes,
              electronic data-processing records, artifacts, or other
              documentary material, regardless of physical form or
              characteristics, made or received pursuant to law or ordinance in
              connection with the transaction of public business by any agency
              of North Carolina or its subdivisions." For information to be
              confidential, a specific provision must be made in the law.
            </p>
            <p className="mt-5 ">
              <strong>For more details visit</strong>
            </p>
            <p>
              <a href="https://www.nc.gov/privacy">
                https://www.nc.gov/privacy
              </a>
            </p>
          </div>
        </CardWrapper>
      ),
    },
  ];

  return (
    <>
      {isDesktop ? (
        <Help navItems={helpItems} />
      ) : (
        <HelpSm navItems={helpItems} />
      )}
    </>
  );
};
export default HelpPage;
