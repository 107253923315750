import React from 'react';
import Widget from './Widget';
import PsurgeContent from './PsurgeContent';


const PsurgeWidget = () => {
  return (
    <Widget title="P-Surge">
      <PsurgeContent />
    </Widget>
  );
}

export default PsurgeWidget;