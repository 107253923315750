import React, { useState } from 'react';
import Widget from './Widget';
import WeatherContent from './WeatherContent';
import HyetographModal from './HyetographModal';


const WeatherWidget = () => {
  const [showRainModal, setShowRainModal] = useState(false);
  return (
    <Widget title="Weather" onActionClick={() => { setShowRainModal(true) }}>
      <WeatherContent />
      {showRainModal && <HyetographModal show={showRainModal} handleClose={() => setShowRainModal(false)} />}
    </Widget>
  );
}

export default WeatherWidget;