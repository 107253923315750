import React from 'react';
import GeneralWidgetSm from './widgets/GeneralWidgetSm';
import StageWidgetSm from './widgets/StageWidgetSm';
import WeatherWidgetSm from './widgets/WeatherWidgetSm';
import TrendWidgetSm from './widgets/TrendWidgetSm';
import ForecastPeakWidgetSm from './widgets/ForecastPeakWidgetSm';
import FlowWidgetSm from './widgets/FlowWidgetSm';
import HistoricalPeakWidgetSm from './widgets/HistoricalPeakWidgetSm';
import useMapStore from 'src/stores/mapStore';
import useGaugeDetails from 'src/lib/hooks/useGaugeDetails';
import useHistoricPeakEvents from 'src/lib/hooks/useHistoricPeakEvents';
import { hasForecastData } from 'src/lib/forecastUtils';
import useUserGroup from 'src/lib/hooks/useUserGroup';
import PsurgeWidgetSm from './widgets/PsurgeWidgetSm';
import PermissionFilter from '../auth/PermissionsFilter';
import { CognitoGroups } from 'src/constants/cognitoGroups';


const CurrentTabContentSm = () => {
  const activeGauge = useMapStore(state => state.activeGauge);
  const { data: gauge } = useGaugeDetails(activeGauge?.attributes?.siteId);
  const { events, isLoading: isHistoryLoading } = useHistoricPeakEvents(activeGauge?.attributes.siteId);
  const { isAdmin, isAdvanced } = useUserGroup();


  const { inService, currentFlow, isCoastal, forecastId } = gauge;

  const hasWeatherData = () => {
    return gauge?.rainCondition === 1;
  }

  
  const showPsurgeWidget = () => {
    return isCoastal && forecastId !== null && forecastId !== undefined && forecastId !== '';
  }


  return (
    <div className="d-flex flex-column dark-gray-background">

      {((gauge.currentElevationMsl > gauge.svcMaxElev) && gauge.svcMaxElev != null) &&
      <div className="d-flex flex-wrap justify-content-between px-3 py-0  position-relative dark-blue-background text-white"><i>The current water elevation exceeds the range of estimated flood impacts available at this site.  The highest available inundation extent is currently displayed.  Actual inundation and impacts may be greater.</i></div>
      }

      <GeneralWidgetSm />
      {inService && (
        <>
          <StageWidgetSm />
          {hasWeatherData() && <WeatherWidgetSm />}
          <PermissionFilter any={[CognitoGroups.ADMIN, CognitoGroups.ADVANCED]}>
            {showPsurgeWidget() && <PsurgeWidgetSm />}
          </PermissionFilter>
          <TrendWidgetSm />
          {hasForecastData(gauge, isAdmin, isAdvanced) && <ForecastPeakWidgetSm />}
          {currentFlow !== null && <FlowWidgetSm />}
          {!isHistoryLoading && events.length > 0 &&
            <HistoricalPeakWidgetSm />
          }
        </>
      )}

    </div>
  );
};


export default CurrentTabContentSm;