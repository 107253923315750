import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { queryFeatures } from '@esri/arcgis-rest-feature-service';
import useConfigData from './useConfigData';


function convertToBinString(date, binSizeHours) {
  const options = { month: 'numeric', day: 'numeric' };

  if (binSizeHours === 1) {
    options.hour = 'numeric';
    options.minute = 'numeric';
  }

  return date.toLocaleString('en-US', options);
}

function binTimeseriesData(data, dateColumn, valueColumn, hours = 240, binSizeHours = 1) {
  if (data.length === 0) {
    return;
  }
  
  // use the current time if the latest date is in the future
  const latestDate = new Date();

  // update the hour of the latest date to the start of the day
  if ( binSizeHours === 24 ) {
    latestDate.setHours(0, 0, 0, 0)
  }

  // Calculate the number of bins needed
  const numBins = Math.ceil(hours / binSizeHours);
  
  // Create an array of bin start times
  const binStarts = Array.from({ length: numBins }, (_, i) => {
    const date = new Date(latestDate);
    date.setHours(date.getHours() - i * binSizeHours, 0, 0, 0);
    return convertToBinString(date, binSizeHours);
  }).reverse();


  const allBins = binStarts.map(date => ({ 
    binStart: date,
    binStartTicks: new Date(date).getTime(),
    value: 0,
    count: 0,
    data: []
  }));

  // iterate over the data and add the value to the correct bin
  data.forEach(entry => {
    const date = new getEasternDate(entry[dateColumn]);
    date.setHours(Math.floor(date.getHours() / binSizeHours) * binSizeHours, 0, 0, 0);
    const binKey = convertToBinString(date, binSizeHours);

    const bin = allBins.find(bin => bin.binStart === binKey);

    if (!bin) {
      return;
    }

    bin.value += entry[valueColumn];
    bin.count += 1;
    bin.data.push({
      ...entry,
      EASTERN_TIME: getEasternDate(new Date(entry[dateColumn])).toLocaleString()
    });
  });

  return allBins;
}

function getEasternDate(now) {
  // Use the time zone offset for Eastern Time (America/New_York)
  const options = { timeZone: 'America/New_York', hour12: false };

  // Create a formatter to get the components in Eastern Time
  const formatter = new Intl.DateTimeFormat('en-US', {
    ...options,
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  });

  // Get the formatted parts
  const parts = formatter.formatToParts(now);

  // Extract the year, month, day, hour, minute, and second
  const year = parts.find((part) => part.type === 'year').value;
  const month = parts.find((part) => part.type === 'month').value - 1; // Month is zero-based in Date
  const day = parts.find((part) => part.type === 'day').value;
  const hour = parts.find((part) => part.type === 'hour').value;
  const minute = parts.find((part) => part.type === 'minute').value;
  const second = parts.find((part) => part.type === 'second').value;

  // Create and return a new Date object in Eastern Time
  return new Date(year, month, day, hour, minute, second);
}

const useRainfall = (gaugeId, binSizeHours = 24) => {
  const { data: config } = useConfigData('Map');

  const { data, isLoading } = useQuery(
    ['rainfall', gaugeId],
    ({ queryKey }) => {
      const [, gaugeId] = queryKey;

      return queryFeatures({
        url: config?.references.rainfall,
        where: `SITE_ID ='${gaugeId}'`,
        outFields: ['*'],
        returnGeometry: false,
      });
    },
    {
      enabled: gaugeId !== undefined,
    }
  );

  const entries = useMemo(() => {
    const rows = data?.features ? data.features : [];

    rows.sort((a, b) => a.attributes.DATA_TIME - b.attributes.DATA_TIME);

    const unpackedRows = rows.map((row) => row.attributes);
    const binnedData = binTimeseriesData(unpackedRows, 'DATA_TIME', 'DATA_VALUE', binSizeHours === 24 ? 24 * 7 : 24*20, binSizeHours);

    return binnedData;
  }, [data, binSizeHours]);

  return { entries, isLoading };
};

export default useRainfall;