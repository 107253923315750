import React, { useState } from 'react';
import WeatherContent from './WeatherContent';
import SingleAccordion from '../../../../components/SingleAccordion';
import HyetographModal from './HyetographModal';


const WeatherWidgetSm = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <SingleAccordion header="Weather" eventKey="0">
        <WeatherContent modalCallback={() => setShowModal(true)} />
      </SingleAccordion>
      {showModal && (
        <HyetographModal
          show={showModal}
          handleClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export default WeatherWidgetSm;
