import { useQuery } from "react-query";
import agent from "../agent";

const useConfigData = (id) => {
  return useQuery(
    ["config", id],
    async ({ queryKey }) => {
      const [, id] = queryKey;
      // return agent.config.get(id);
      const response = await fetch(`${window.location.origin}/mapConfig.json`);
      const mapConfig = await response.json();
      return mapConfig;
    },
    {
      enabled: true,
      staleTime: Infinity,
    }
  );
};

export default useConfigData;
