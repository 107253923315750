import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip } from 'chart.js';
import ZoomPlugin from 'chartjs-plugin-zoom';


const Hyetograph = ({ rainfallData }) => {
  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, ZoomPlugin);

  const height = 350;

  const historicalData = useMemo(() => {
    const labels = rainfallData.map((d) => d.binStart);
    const values = rainfallData.map((d) => d.value);

    return {
      labels,
      values,
    };
  }, [rainfallData]);

  const data = {
    labels: historicalData.labels,
    datasets: [{
      label: 'Precipitation in Total Inches',
      data: historicalData.values,
      borderWidth: 1,
      backgroundColor: '#1455C1',
    }]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 20,
        right: 20,
        top: 20,
        bottom: 20
      }
    },
    scales: {
      y: {
        title: {
          display: true,
          text: 'Precipitation in Total Inches',
        },
      },
      x: {
        min: data.labels.length - 36, // 36 hours before the current time
        max: data.labels.length,
      },
    },
    plugins: {
      zoom: {
        pan: {
          // pan options and/or events
          enabled: true,
          mode: 'x',
        },
        zoom: {
          // zoom options and/or events
          wheel: {
            enabled: false,
          },
          pinch: {
            enabled: false
          },
          mode: 'x',
        },
      },
    },
  };
  

  return (
    <div style={{ height: height }}>
      <Bar data={data} options={options} />
    </div>
  );
};

Hyetograph.propTypes = {
  rainfallData: PropTypes.array.isRequired,
};

export default Hyetograph;
