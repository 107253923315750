import React from 'react';
import SingleAccordion from '../../../../components/SingleAccordion';
import PsurgeContent from './PsurgeContent';


const PsurgeWidgetSm = () => {
  return (
    <SingleAccordion 
      header="P-Surge"
      eventKey="0"
      defaultEventKey={null}
    >
      <PsurgeContent />
    </SingleAccordion>
  );
}

export default PsurgeWidgetSm;