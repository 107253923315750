import React, { useState } from 'react';
import PropTypes from 'prop-types';
import 'chartjs-adapter-date-fns';
import { Form, Modal } from 'react-bootstrap';
import useCustomMediaQuery from 'src/lib/hooks/useCustomMediaQuery';
import useMapStore from 'src/stores/mapStore';
import SingleAccordion from 'src/components/SingleAccordion';
import LoadingDisplay from 'src/components/LoadingDisplay';
import useRainfall from 'src/lib/hooks/useRainfall';
import Hyetograph from './Hyetograph';


const HyetographModal = ({ show, handleClose }) => {
  const [selectedInterval, setSelectedInterval] = useState('24');

  const { isDesktop } = useCustomMediaQuery();
  const gauge = useMapStore(state => state.activeGauge);
  const { entries: rainfallData, isLoading } = useRainfall(gauge?.attributes?.siteId, parseInt(selectedInterval));

  if (isLoading) {
    return <LoadingDisplay />;
  }

  return (
    <Modal
      className={!isDesktop ? 'mobile-modal' : ''}
      size="lg"
      centered
      fullscreen={!isDesktop}
      show={show}
      onHide={handleClose}
      animation={true}
      scrollable={true}
    >
      <Modal.Header closeVariant="white" closeButton>
        <Modal.Title>{`${gauge.attributes.name} - Rainfall`}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-md-5 pt-md-4">
        <SingleAccordion
          header="Instructions"
          eventKey="0"
          defaultEventKey={null}
        >
          <div className="d-flex">
            Click and drag on any section of the graph to pan across up to 30 days past.          </div>
        </SingleAccordion>

        <div className="mt-3 mb-3">
          <Form.Select 
            aria-label="Select time interval"
            value={selectedInterval}
            onChange={(e) => setSelectedInterval(e.target.value)}
          >
            <option value="24">Daily (24 hours)</option>
            <option value="1">Hourly (1 hour)</option>
          </Form.Select>
        </div>

        <div className="white-background mt-3">
          { rainfallData && <Hyetograph rainfallData={rainfallData} /> }
        </div>
      </Modal.Body>
    </Modal>
  );
}

HyetographModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  minDisplayDate: PropTypes.instanceOf(Date)
};

export default HyetographModal; 