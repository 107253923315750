import React from 'react';
import PropTypes from 'prop-types';
import useMapStore from 'src/stores/mapStore';
import PrimaryButton from 'src/components/PrimaryButton';
import LoadingDisplay from 'src/components/LoadingDisplay';
import useGaugeDetails from 'src/lib/hooks/useGaugeDetails';
import useCustomMediaQuery from 'src/lib/hooks/useCustomMediaQuery';

const FlowContent = ({ modalCallback }) => {
  const gauge = useMapStore(state => state.activeGauge);
  const { data: gaugeDetails, isLoading } = useGaugeDetails(gauge?.attributes?.siteId);
  const { isDesktop } = useCustomMediaQuery();

  if (isLoading) {
    return <LoadingDisplay />;
  }

  return (
    <>
      <span className="d-flex justify-content-center align-items-center fs-3">{Math.round(gaugeDetails.currentFlow)} cfs</span>
      {!isDesktop && 
        <div className="d-flex flex-column mt-3">
          <PrimaryButton size="sm" className="rounded-pill py-1" onClick={() => modalCallback()}>
            <span>View Details</span>
          </PrimaryButton>
        </div>
      }
    </>
  );
}

FlowContent.propTypes = {
  modalCallback: PropTypes.func
};

export default FlowContent;
