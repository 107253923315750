import { useQuery } from 'react-query';
import axios from 'axios';
import useConfigData from './useConfigData';
import * as webMercatorUtils from '@arcgis/core/geometry/support/webMercatorUtils.js';

const fetchInundationData = async ({ queryKey }) => {
  // eslint-disable-next-line
  const [_, x, y, url] = queryKey;

  if (!x || !y) {
    throw new Error('x and y are required');
  }

  const size = 50;

  const response = await axios.get(url, {
    params: {
      SERVICE: 'WMS',
      VERSION: '1.3.0',
      REQUEST: 'GetFeatureInfo',
      LAYERS: 'inundation',
      QUERY_LAYERS: 'inundation',
      INFO_FORMAT: 'application/json',
      FEATURE_COUNT: 1,
      I: 1,
      J: 1,
      WIDTH: 3,
      HEIGHT: 3,
      CRS: 'EPSG:3857',
      BBOX: `${x - size},${y - size},${x + size},${
        y + size
      }`,
    },
  });

  if (!response.data ||  response.data.numberReturned === 0) {
    return null;
  }

  const feature = response.data.features[0];

  return feature.properties.Band1;
};

const useInundationData = (latitude, longitude) => {
  const { data: config } = useConfigData('Map');

  // convert to 3857 from lat/long using arcgis api
  const point = {
    x: longitude,
    y: latitude,
  };

  const [x, y] = webMercatorUtils.lngLatToXY(point.x, point.y);
  
  return useQuery(
    [
      'inundationData',
      x,
      y,
      config.references.tropicalStormSurge,
    ],
    fetchInundationData,
    {
      enabled: !!latitude && !!longitude,
      refetchInterval: 10 * 60 * 1000, // Refetch every 10 minutes
      staleTime: 60 * 60 * 1000, // Consider data stale after 1 hour
    }
  );
};

export default useInundationData;
