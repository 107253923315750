import React from 'react';
import useGaugeDetails from 'src/lib/hooks/useGaugeDetails';
import useMapStore from 'src/stores/mapStore';
import NameValueText from 'src/components/NameValueText';
import LoadingDisplay from 'src/components/LoadingDisplay';
import useInundationData from 'src/lib/hooks/useInundationData';


const PsurgeContent = () => {
  const activeGauge = useMapStore((state) => state.activeGauge);
  const { data: gaugeDetails, isLoading: gaugeDetailsLoading } =
    useGaugeDetails(activeGauge?.attributes?.siteId);

  const {
    data: inundationData,
    isLoading: inundationDataLoading,
    error,
  } = useInundationData(gaugeDetails?.latitude, gaugeDetails?.longitude);

  const { isCoastal } = gaugeDetails;

  if (gaugeDetailsLoading || inundationDataLoading) {
    return <LoadingDisplay />;
  }

  if (error) return <div>Error fetching inundation data.</div>;
  if (!isCoastal) return <div>Please select a coastal gauge.</div>;

  return (
    <div className="d-flex flex-column justify-content-center align-items-center h-100">
      {!inundationData ? (
        <div>P-Surge not issued at this time.</div>
      ) : (
        <NameValueText
          label="P-Surge Max Value"
          value={`${inundationData} ft`}
          className="justify-content-center align-items-center"
        />
      )}
    </div>
  );
};

export default PsurgeContent;
